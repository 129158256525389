import {
  collection,
  query,
  getDoc,
  doc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'companies';

const Companies = {};

Companies.getAll = async () => {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
  });
};

Companies.findById = async (id) => {
  var result = undefined;
  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

Companies.update = async (company) => {
  var result = undefined;

  var newCompanyToSave = { ...company };
  delete newCompanyToSave.id;
  const docRef = doc(db, collectionName, company.id);

  await updateDoc(docRef, newCompanyToSave);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

Companies.updateById = async (id, newCompany) => {
  var result = undefined;
  const docRef = doc(db, collectionName, id);
  await updateDoc(docRef, newCompany);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id: docSnap.id };
  }

  return result;
};

export default Companies;
