import {
  signInWithPopup,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signOut,
  createUserWithEmailAndPassword,
  getAuth,
} from 'firebase/auth';
import { deleteApp, initializeApp } from 'firebase/app';
import WrongEmailOrPasswordError from 'utils/errors/WrongEmailOrPasswordError';
import User from 'models/domain/User';
import { auth } from './init';
import defaultConfig from './firebase-config.json';
import Users from './users';

const provider = new GoogleAuthProvider();

const Auth = {};

const fetchRelevantUser = async (user) => {
  let result = undefined;
  const signInUser = await Users.findByEmail(user.email);

  if (signInUser) {
    result = new User(user, signInUser);
  }

  return result;
};

Auth.getCurrentUser = async () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      unsubscribe();
      let result = undefined;

      if (user) {
        result = fetchRelevantUser(user);
      }

      resolve(result);
    }, reject);
  });
};

Auth.signOut = async () => {
  await signOut(auth);
};

Auth.signInWithGoogle = async () => {
  var response = await signInWithPopup(auth, provider);
  const user = fetchRelevantUser(response.user);

  if (!user) {
    await Auth.logOut();
    return undefined;
  }

  return user;
};

Auth.signInWithEmail = async (email, password) => {
  try {
    const response = await signInWithEmailAndPassword(auth, email, password);
    const user = fetchRelevantUser(response.user);

    if (!user) {
      await Auth.signOut();
      return undefined;
    }

    return user;
  } catch (err) {
    throw new WrongEmailOrPasswordError('Fel e-postadress eller lösenord.');
  }
};

Auth.createUserAndSendPasswordReset = async (email) => {
  try {
    const randomPassword = Math.random().toString(36).slice(2); // Random string
    // const auth = getAuth();

    var configString = process.env.REACT_APP_FIREBASE_CONFIG;
    var config = configString ? JSON.parse(configString) : defaultConfig;

    // Initialize a secondary Firebase App
    const secondaryApp = initializeApp(config, 'Secondary');

    // Get the Auth instance for the secondary app
    const secondaryAuth = getAuth(secondaryApp);

    // Create the user with the secondary Auth instance
    await createUserWithEmailAndPassword(
      secondaryAuth,
      email,
      randomPassword,
    ).catch((error) => {});

    // User is created, now send password reset email
    await sendPasswordResetEmail(secondaryAuth, email);

    // Delete the secondary app
    await deleteApp(secondaryApp);

    return {
      success: true,
      message: 'User created and password reset email sent',
    };
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    return { success: false, errorCode, errorMessage };
  }
};

Auth.resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);

    return { success: true, message: 'Password reset email sent' };
  } catch (error) {
    const message = error.message;
    // Handle specific errors if needed
    return { success: false, message };
  }
};

export default Auth;
