import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(
      () => import('views/auth-views/authentication/login'),
    ),
  },
  {
    key: 'register',
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(
      () => import('views/auth-views/authentication/register'),
    ),
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(
      () => import('views/auth-views/authentication/forgot-password'),
    ),
  },
];

export const adminRoutes = [
  {
    key: 'top-list',
    path: `${APP_PREFIX_PATH}/top-list/*`,
    component: React.lazy(() => import('views/app-views/top-list')),
  },
  {
    key: 'compare',
    path: `${APP_PREFIX_PATH}/compare/*`,
    component: React.lazy(() => import('views/app-views/compare')),
  },
  {
    key: 'office-location',
    path: `${APP_PREFIX_PATH}/office-location/*`,
    component: React.lazy(() => import('views/app-views/office-location')),
    exclusive: true,
  },
];
